require('./bootstrap');
import { DateTime } from 'luxon';
import Sortable from 'sortablejs';
var toastAlert;
$(document).ready(function () {
    // Global
    initGlobalSettings();

    $('#input-timezone').not('.disable-auto-select').val((DateTime.now()).zoneName);

    $('.datatable').each(function () {
        var dtConfig = $(this).data('datatableConfig');
        $(this).DataTable((dtConfig ? dtConfig : '{}'));
    });

    $('.copy-to-clipboard').off('click').on('click', function () {
        var elmButton = $(this);
        var copiedText = copyToClipboard(elmButton.data('target'));
        elmButton.html('Link Copied');
        setTimeout(function () {
            elmButton.html('Copy Link');
        },3000);
    });

    $("#referral-link").on('click', function () {
        $('.copy-to-clipboard').trigger('click');
    });

    $("#email-referral-link-button").on('click', function () {
        var elmInput = $($(this).data('targetInput')),
            elmError = $($(this).data('targetError')),
            formUrl = elmInput.data('url'),
            email = elmInput.val();

        elmInput.removeClass('invalid-form-input');
        elmError.html('');

        if (isEmailValid(email) === false) {
            elmInput.addClass('invalid-form-input');
            elmError.removeClass('text-success').addClass('text-danger').html(elmError.data('text'));
            return false;
        }

        if (formUrl) {
            $.post(formUrl, {"email": email})
                .done(function(res) {
                    if (res.status === 'ok') {
                        elmInput.val('');
                        elmError.removeClass('text-danger').addClass('text-success').html(res.message);
                    }
                })
                .fail(function(res) {
                    if (res.responseJSON) {
                        elmInput.addClass('invalid-form-input');
                        elmError.removeClass('text-success').addClass('text-danger').html(res.responseJSON.errors.email[0]);
                    }
                })
                .always(function() {

                });
        }
    });

    $('#account-details-forms').on('click', '.btn-save', function (e) {
        e.preventDefault();

        var elmActionButtons = $(this).parent().find('.btn').attr('disabled', true).addClass('disabled');

        var form = $(this).closest('form');
        var formURL = form.attr('action');
        var formData = form.serialize();

        $.post(formURL, formData, function() {
            var avatarMarkup = $('#account-details-forms').find('.avatar-container').html();
            $('#account-details .avatar-container').html(avatarMarkup);

            $(":input").each(function(){
                var key = $(this).attr('name');
                var val = $(this).val();
                $('#' + 'form-input-' + key).html(val);
            });

            offcanvasClose();
        }).done(function() {
            // console.log( "second success" );
        }).fail(function(data) {
            var response = JSON.parse(data.responseText);
            var errorString = '<div>Please correct the following error(s):</div>'
            errorString += '<ul>';
            $.each(response.errors, function( key, value) {
                errorString += '<li>' + value + '</li>';
            });
            errorString += '</ul>';

            $('#account-details-forms .input-error').removeClass('hide').html(errorString);
        }).always(function() {
            elmActionButtons.attr('disabled', false).removeClass('disabled');
        });
    });

    $('#account-details-forms #color-table').on('click', 'td', function (e) {
        var hexColor = '#' + $(this).data('color');
        $('#account-details-forms').find('.avatar-container > i').css('color', hexColor)
        $('#account-details-forms').find('.avatar-container-sm  > i').css('color', hexColor);

        var elmAvatarContainer = $('#account-details-forms .avatar-container');
        $('#account-details-forms input[name=avatar]').val(elmAvatarContainer.html());
    });

    $('#account-details-forms').on('click', '.avatar-container-sm', function (e) {
        var icon = $(this).html();
        var elmAvatarContainer = $('#account-details-forms .avatar-container').html(icon);
        $('#account-details-forms input[name=avatar]').val(elmAvatarContainer.html());
    });

    setTimeout(function() {
        $('.ad-popdown').slideDown(1000);
    }, 750);

    setTimeout(function() {
        if (screen.width >= 1400) {
            $('.ad-skyscraper').removeClass('hide');
        }
    }, 1250);

    var elmSortableItems = document.getElementById('sortable-items');
    if (elmSortableItems !== null) {
        new Sortable(elmSortableItems, {
            animation: 150,
            ghostClass: 'bg-primary',
            direction: 'vertical'
        });
    }
});

function initGlobalSettings() {
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
        cache: false
    });
    var emlToastAlert = document.getElementById('toast-alert');
    toastAlert = new bootstrap.Toast(emlToastAlert);
}

window.triggerToastAlert = function(msg, cssBgColor, cssTxtColor) {
    var elmToastAlert = $('#toast-alert');
    var bgColorClasses = 'bg-info bg-success bg-warning bg-danger bg-primary';
    var textColorClasses = 'text-info text-success text-warning text-danger text-primary text-white';

    elmToastAlert.removeClass(bgColorClasses).addClass((cssBgColor ? cssBgColor : 'bg-info'));
    elmToastAlert.removeClass(textColorClasses).addClass((cssTxtColor ? cssTxtColor : 'text-white'));
    elmToastAlert.find('.toast-body').html(msg);
    toastAlert.show();
}

function copyToClipboard(targetId) {
    targetId = targetId.replace('#', '');
    var copyText = document.getElementById(targetId);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    return copyText.value;
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function isEmailValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
